/* CSS */
import "@js/parts/css";

/* JS */
import * as Helpers from './modules/helpers.js';
/*import * as ResponsiveNavigation from './modules/responsive-navigation.js';*/

/* Run the initialisation functions for various functionality */
Helpers.initWindowHasScrolled();
Helpers.initAnimatedIntroBlocks();
Helpers.initPopups();
/* Helpers.justIn(); */
Helpers.dismissNotice();
Helpers.timelineChange();
/*Helpers.initVAT(); Now called via the Twig include */
/*ResponsiveNavigation.initNavigation(); Now called via the Twig include */

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR");
	});
}
